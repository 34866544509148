<template lang="">
    <div>
       <div class="row">
        <div class="col-12 justify-content-end d-flex my-5">
            <button class="btn btn-primary"><i class="fas fa-file mx-2"></i>Pdf Al</button>
        </div>
        <div class="col-12 d-flex my-5">
            <div class="col-6"><Lines1/></div>
            <div class="col-6"><Column/></div>

        </div>
       </div>
    </div>
</template>
<script>
export default {
    name:"rapor-takip",
    components:{
    Column: () => import('@/components/dashboard/charts/column'),
    Lines1: () => import('@/components/dashboard/charts/lines1'),
    }
}
</script>
<style lang="">
    
</style>